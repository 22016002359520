@import 'src/styles/variables';

.yearsToSaveLabel {
  color: $violet;
  font-size: 36px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 24px;
}

.label {
  color: $black100;
  text-align: center;
}

.spaced {
  margin-bottom: 32px;
}

.compareWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
  margin: 16px 0 32px;
}

.compareWrapper::after {
  content: 'vs';
  position: absolute;
  top: 20px;
  z-index: 1;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: $white;
  font-weight: $mediumFontWeight;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $black10;
}

.compareTable {
  width: 50%;
  text-align: center;
  border: 1px solid $black10;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 16px 20px 0 rgba(36, 85, 158, 0.08);
}

.compareTable.purple {
  border: 1px solid $purple100;
}

.tableHeader {
  padding: 10px;
  background: $black6;
  color: $black100;
}

.compareTable.purple .tableHeader {
  background: $purple50;
}

.tableBody {
  padding: 10px;
}

.tableBody > p {
  color: $black60;
  font-size: 14px;
}

.colleagues {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.colleagueRow {
  display: flex;
  align-items: center;
}

.userIcon {
  rect {
    fill: $purple50;
  }

  path {
    fill: $purple400;
  }
}

.colleagueName {
  margin-left: 10px;
  font-size: 14px;
  color: $black100;
}

.colleagueSavings {
  font-weight: $mediumFontWeight;
  font-size: 14px;
  color: $black100;
  margin-left: auto;
}

.button {
  margin-top: 32px;
}
